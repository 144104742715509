// Buttons

button {

  background:transparent;
  border-radius:0px!important;
  border:0px;
  color:$white;
  cursor:pointer;
  display: flex;
  flex-wrap:nowrap;
  align-items: center;
  white-space: nowrap;
  @include transition-all(.3s);

  font-size: 10px;


  &.button__disabled,
  &.button__disabled:hover {
    pointer-events: none!important;
    cursor: default!important;
    background-color: $gravel!important;
    color: $blade!important;
    border: 1px solid $gravel!important;
    transform:scale(1)!important;
    font-weight: $font-bold;
    text-transform: uppercase;
    padding: 0px 7px;
    line-height: 2.75em;

    .button__text {
      @include transition-all(.3s);
      transform:translateX(4px);
    }

    .icon,
    &.button__icon--right .icon,
    &.button__icon--left .icon {
      font-size:16px;
      @include transition-all(.3s);
      margin:0px;
    }
    &.button__icon--right .icon {
      transform:translateX(3px);
    }

  }

  &.button__box {
    color:$white;
    background-color: $nflxred;
    border: 1px solid $nflxred;
    font-weight: $font-bold;
    text-transform: uppercase;
    padding: 0px 14px;
    line-height: 2.75em;
    position: relative;
    @include box-shadow(0px,0px,0px,rgba(0,0,0,0));
    overflow: hidden;

    &.button__grey {
      color:$white!important;
      background-color: $gravel!important;
      border: 1px solid $gravel!important;;
      &:hover {
        background-color: $abbey!important;;
        border: 1px solid $abbey!important;;
      }
    }

    .button__text {
      @include transition-all(.3s);
      transform:translateX(4px);
    }

    .icon,
    &.button__icon--right .icon,
    &.button__icon--left .icon {
      font-size:16px;
      @include transition-all(.3s);
      margin:0px;
    }
    &.button__icon--right .icon {
      transform:translateX(3px);
    }

    &:before, &:after {
      opacity:0;
      @include blur(10px);
      @include transition-all(.3s);
      content:'';
      position: absolute;
      top:0px;
      left:-30px;
      display: block;
      background-color:$white;
      width:20px;
      height:100%;
      transform: skewX(-15deg);
    }
    &:before {
      opacity:.5;
      width:20px;
      // transition-timing-function: ease-out;
    }
    &:after {
      opacity:0;
      width:5px;
      transition-delay: .2s;
      // transition-timing-function: ease-in;
    }

    &:hover {

      color:$white;
      background-color: $nflxred;
      border: 1px solid $nflxred;
      transform:scale(1.05);
      @include box-shadow(0px,0px,20px,rgba(21,21,21,1));

      &:before, &:after {
        opacity:0;
        left:120%;
      }
      &:before {
        width:5px;
        opacity:0;
      }
      &:after {
        width:20px;
        opacity:.5;
      }

      &.button__icon--right .icon {
        transform:translateX(3px);
      }
      .button__text {
        transform:translateX(2px);
      }
      // color:$white;
      // border: 1px solid $nflxred;
      // background-color:$nflxred;
    }
    &.highlighted {
      &:hover {
        // color:$nflxred;
        // border: 1px solid $nflxred;
        // background-color:transparent;
      }
    }

  }


  &.button__outline {
    color:$nflxred;
    background-color: transparent;
    border: 1px solid $nflxred;
    font-weight: $font-bold;
    text-transform: uppercase;
    padding: 0px 14px;
    line-height: 2.75em;
    position: relative;
    @include box-shadow(0px,0px,0px,rgba(0,0,0,0));
    overflow: hidden;

    &.button__outline--grey  {
      border-color:#999;
      color:#999;
    }

    .button__text {
      @include transition-all(.3s);
      transform:translateX(4px);
    }

    .icon,
    &.button__icon--right .icon,
    &.button__icon--left .icon {
      font-size:16px;
      @include transition-all(.3s);
      margin:0px;
    }
    &.button__icon--right .icon {
      transform:translateX(3px);
    }

    &:before, &:after {
      opacity:0;
      @include blur(10px);
      @include transition-all(.3s);
      content:'';
      position: absolute;
      top:0px;
      left:-30px;
      display: block;
      background-color:$white;
      width:20px;
      height:100%;
      transform: skewX(-15deg);
    }
    &:before {
      opacity:.25;
      width:20px;
      // transition-timing-function: ease-out;
    }
    &:after {
      opacity:0;
      width:5px;
      transition-delay: .2s;
      // transition-timing-function: ease-in;
    }

    &:hover {

      transform:scale(1.05);
      @include box-shadow(0px,0px,20px,rgba(21,21,21,1));

      &:before, &:after {
        opacity:0;
        left:120%;
      }
      &:before {
        width:5px;
        opacity:0;
      }
      &:after {
        width:20px;
        opacity:.25;
      }

      &.button__icon--right .icon {
        transform:translateX(3px);
      }
      .button__text {
        transform:translateX(2px);
      }
      // color:$white;
      // border: 1px solid $nflxred;
      // background-color:$nflxred;
    }
    &.highlighted {
      &:hover {
        // color:$nflxred;
        // border: 1px solid $nflxred;
        // background-color:transparent;
      }
    }

  }



  &.button__return {
    line-height: 5em;
    // padding-right: 30px;
  }
  &.button__underline {
    font-size:16px;
    flex-wrap:wrap!important;
    &:after {
      content:"";
      height:1px;
      background: rgb(229,9,20);
      background: linear-gradient(90deg, rgba(229,9,20,0) 0%, rgba(229,9,20,1) 25%, rgba(229,9,20,1) 75%, rgba(229,9,20,0) 100%);
      margin:0px auto;
      @include transition-all(.3s);
      opacity: .3;
      margin-top:5px;
      flex-basis:70%;
    }
    &:hover,
    &.highlighted {
      &:after {
        opacity:1;
        flex-basis:100%;
      }
    }
  }

  span {
  }

  .icon {
    // height: 15px;
    &.material-icons {
      // transform: translate(0px,-30%);
    }
  }

  &.button__icon--right {
    .icon {
      margin-left:5px;
    }
  }
  &.button__icon--left {
    .icon {
      margin-right:7px;
    }
  }

}
