.titledetail-content__container {
  top:0px;
}

//@keyframes bannerFadeIn {
//  0% {
//    opacity:0;
//  }
//  100% {
//    opacity:1;
//  }
//}

.titledetail-banner {
  background-position: center top;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #151616;
  width: 100%;
  //height:90vh;
  top:0px;
  //padding-top:75px;
  //animation: 1s ease-out 0s 1 bannerFadeIn;
  //transition: all .2s ease;
}

.titledetail__banner--gradient {
  display:block;
  position: absolute;
  width: 100%;
  height:100%;
  bottom:0px;
  left:0px;
  z-index:1;
  pointer-events: none;
  background-color: transparent;
  background-image: url(../img/bg/gradient-bottom-top.png);
  background-position: center bottom;
  background-repeat: repeat-x;
  background-size: 1px 120px;
}
.titledetail__banner--gradient-top {
  position: fixed;
  transform:rotate(180deg);
  top:0;
  height: 75px;
  background-size: 1px 75px!important;
}
.titledetail__banner--gradient-flipped {
  transform:rotate(180deg);
  top:99%;
  height: 120px;
  background-size: 1px 120px!important;
}

.titledetail-banner-nav-container {
  width: 100%;
  pointer-events: all;
  z-index: 3;
  background-color: #151616;
}


.titledetail__nav-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  //margin: 2rem 0;
  padding:17px 7px;
  position: sticky;
  top: 17px;
  z-index: 111;
  @include transition-all(1s);

  &.active {
    background-color: rgba(0,0,0,.9);
  }

  div {
    flex-grow: 1;
    padding:0 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      transition: all .2s ease;
      background-color: rgba(255,255,255,.05);
      border:1px solid rgba(255,255,255,0);
      padding:10px 10px;
      text-align: center;
      display: inline-block;
      flex-grow: 1;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 300;
      letter-spacing: .15rem;
      color:rgba(255,255,255,.5);
      box-shadow: rgba(0,0,0,.33) 3px 3px 20px;

      backdrop-filter: blur(7px);
      -webkit-backdrop-filter: blur(7px);

      &:hover {
        color:rgba(255,255,255,.9);
        border:1px solid rgba(255,255,255,.1);
        background-color: rgba(255,255,255,0);
      }
      &.active {
        font-weight:600;
        color:#fff;
        border:1px solid rgba(255,255,255,.1);
        background-color: transparent;
      }
      &:focus {
        outline:none!important;
        border:1px solid rgba(255,255,255,.1)!important;
        background-color: transparent;
      }
    }
  }
}


.sidebar__container--titlepage-sticky {
  position: relative;
  top:0px;
}



.titledetail__sidebar--background-container {

  position: absolute;
  height:100%;
  top:0;
  left:0;
  width:100%;
  z-index:1;
  pointer-events: none;

  .titledetail__sidebar--background {

    background: rgb(21,22,22);
    background: linear-gradient(0deg, rgba(0,0,0,.5) 0%, rgba(0,0,0,.5) 50%, rgba(0,0,0,0) 90%);
    height:100%;
    //backdrop-filter: blur(10px);
  }
}


.titledetail-bg-streams {
  opacity: .05;
  display:block;
  position: fixed;
  width: 100vw;
  height:100vh;
  top:0px;
  left:0px;
  z-index:0;
  pointer-events: none;
  background-color: transparent;
  background-image: url(../img/bg/bg-streams-24.png);
  background-size: cover;
  background-position: right center;
  background-repeat: repeat-y;
}


@media (min-width: $break-md) {

  .titledetail-content__container {
    top:0px;
  }

  .titledetail-banner-nav-container {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: transparent;
  }

  .titledetail-banner {
    //padding-top:0px;
  }

  .titledetail__banner--gradient {
    background-size: 1px 378px;
  }


  .sidebar__container--titlepage-sticky {
    position: sticky;
    top:0px;
    padding-top:75px;
    margin-top:-250px;
  }

}