form {

  .form-control::-webkit-input-placeholder { color: $white!important; }  /* WebKit, Blink, Edge */
  .form-control:-moz-placeholder { color: $white!important; }  /* Mozilla Firefox 4 to 18 */
  .form-control::-moz-placeholder { color: $white!important; }  /* Mozilla Firefox 19+ */
  .form-control:-ms-input-placeholder { color: $white!important; }  /* Internet Explorer 10-11 */
  .form-control::-ms-input-placeholder { color: $white!important; }  /* Microsoft Edge */
  // .form-control::-ms-input-placeholder { color: $white!important; }  /* Microsoft Edge */

  input.form-control, .form-control {
    @include transition-all(.3s);
    background: transparent;
    border-radius:0px;
    border: 0px;
    color:$white;
    border-bottom: 1px solid $white;
    opacity:$fadedFormOpacity;
    &:focus {
      outline:none!important;
      outline-width: 0!important;
      box-shadow: none !important;
      border: 0px;
      color:$white;
      border-bottom: 1px solid $white;
      background: transparent;
      opacity:$fullFormOpacity;
    }
  }
  .icon {
    @include transition-all(.3s);
    opacity:$fadedFormOpacity;
  }
  &:hover {
    .icon {
      opacity:$fullFormOpacity;
    }
  }
}
