



.video__container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;

  iframe,video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
  }
}

.video__container--placeholder {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  width:100%;
  display:none;
}












.fyseedescription__container {

  font-size:.9rem;
  line-height:1.2em;
  padding:10px 0px 30px;

}


.fyseesignup__container {

  .fyseesignup__input {
    width:100%;
    border:0px;
    border-bottom:1px solid rgba(255,255,255,.2);
    background: transparent;
    @include transition-all(.2s);
    color:$white;
    margin-bottom:20px;
    font-size:14px;
    line-height:1.8rem;
  }
  .fyseesignup__description {
    font-size:.9rem;
    line-height:1.3em;
  }
  .fyseesignup__message {
    font-size:.9rem;
    line-height:1.3em;
  }
}


.fyseecomingsoon__container {
  // background: #ccc;
  position: relative;
  overflow: hidden;


  .fyseecomingsoon__shadow {
    position: absolute;
    top:0px;
    left:0px;
    width: 100%;
    pointer-events: none;
  }


  .fyseecomingsoon__shadow-bottom {
    display: none;
    background: rgb(21,22,22);
    background: linear-gradient(180deg, rgba(21,22,22,0) 0%, rgba(21,22,22,1) 95%);
    width:100%;
    height:20%;
    position: absolute;
    bottom:0px;
    left:0px;
    z-index:1;
    pointer-events: none;
  }

  .fyseecomingsoon__scroll {

    // padding-top:30px;
    width:100%;

    padding-right:20px;

    overflow:visible;
    height:auto;
    position: static;
    padding-bottom:0px;


    .fyseecomingsoon__item-container {
      margin-top:25px;
      &:first-of-type {
        margin-top:0px;
      }
    }


    .fyseecomingsoon__title {
      color: $white;
      font-size: 1rem;
      text-transform: uppercase;
      font-weight: $font-light;
      opacity: .4;
      letter-spacing: .2rem;
      margin:30px 0px;
      // text-align: center;
    }


    .fyseecomingsoon__item-date {
      color: $white;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: $font-bold;
      letter-spacing: .1rem;
      // opacity: .75;
      line-height: 1rem;
      margin-bottom:8px;

    }


    .fyseecomingsoon__item-rule {
      width:100%;
      opacity:.15;
      margin:0px auto 15px;
      border:0px;
      // border-top:1px solid $white;
      height:1px;
      // margin-top:15px;
      background: rgb(255,255,255);
      // background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 25%, rgba(255,255,255,1) 75%, rgba(255,255,255,0) 100%);
    }


    .fyseecomingsoon__item {

      margin-top:25px;
      &:first-of-type {
        margin-top:0px;
      }


      .fyseecomingsoon__item-details {

        .fyseecomingsoon__item-time {
          font-size:.8rem;
          font-weight:$font-bold;
        }
        .fyseecomingsoon__item-videotitle {
          font-size:1rem;
          color:$white;
          // opacity:.5;
          font-weight:$font-light;
          line-height:1.2em;

        }
        .fyseecomingsoon__item-showtitle {
          font-size:.8rem;
          font-weight: 600;
          opacity:.4;
          text-transform: uppercase;
          // letter-spacing: .2rem;
          margin-bottom:5px;
        }
      }

    }

  }


}



.fyseehero__playlist-scrollcontainer {

  position: relative;

  .fyseehero__playlist-scroll {
    // position: absolute;
    // top:0px;
    // left:0px;
    // width:100%;
    // height:100%;
    // overflow:hidden;
    // overflow-y: scroll;
    // -webkit-overflow-scrolling: touch;
  }

  .fyseehero__playlist-scrollshadow {
    display: none;


    z-index:2;
    pointer-events: none;
  }

}







.icon__load  {
  position: absolute;
  width: 80px!important;
  height: 80px!important;
  z-index: 1;
  left: 50%!important;
  top: 50%!important;
  transform: translate(-50%,-50%);
  cursor: default;
  opacity:.5

}


.icon__play  {
  position: absolute;
  width: 20%!important;
  height: auto!important;
  z-index: 1;
  left: 50%!important;
  top: 50%!important;
  transform: translate(-50%,-50%);
  cursor: pointer;
  @include transition-all(.2s);
  opacity: .6;
  img {
    width:100%;
    height:auto;
  }
  &:hover {
    // width: 20.5%!important;
    opacity:1;
  }
}






.fyseerelatedvideos__container {
  margin-top:40px;
  padding-top:20px;
  border-top:1px solid $thunder;
}








@keyframes videoThumbLoadAnimation {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}



.fyseevideo__container {

  position: relative;

  .videothumbnail__image {
    cursor: pointer;
    animation-duration: .5s; /* the duration of the animation */
    animation-timing-function: ease-out; /* how the animation will behave */
    animation-fill-mode: backwards;
    animation-delay: .5s; /* how long to delay the animation from starting */
    animation-iteration-count: 1; /* how many times the animation will play */
    animation-name: videoThumbLoadAnimation; /* the name of the animation we defined above */
  }

  .fyseevideo__inner {

    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    // z-index:-1;

    .videobumper__container,
    .videofull__container,
    .videocontrolsoverlay__container,
    .videothumb__container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }


    .videothumb__container {
      z-index:4;
      @include transition-all(.3s);
      opacity:1;
      &.disabled {
        pointer-events: none;
        opacity:0
      }
    }
    .videobumper__container,
    .videobumper__video {
      z-index:2;
      // @include transition-all(.3s);
      opacity:1;
      &.disabled {
        pointer-events: none;
        opacity:0
      }
    }
    .videofull__container,
    .videofull__video {
      // pointer-events: none;
      cursor: default;
      z-index:1;
      @include transition-all(.3s);
      opacity:1;
      &.disabled {
        pointer-events: none;
        opacity:0
      }
    }


  }



  .fyseevideometa__container {

    .fyseevideometa__small {
      font-size:.8rem;
      color:$white;
      opacity:.35;
      font-weight:$font-bold;
      text-transform: uppercase;
      margin-bottom:5px;
    }
    .fyseevideometa__large {
      line-height:1.2em;
      font-size:1rem;
      color:$white;
      // font-weight:$font-bold;
    }
    .fyseevideometa__xlarge {
      font-weight:$font-bold;
      color:$white;
      font-size:1.5rem;
      line-height:1.2em;
    }

  }
}


.fyseevideo__tt {
  height:200px;
  display:flex;
  justify-content: center;
  align-items: center;
  animation-duration: .5s; /* the duration of the animation */
  animation-timing-function: ease-out; /* how the animation will behave */
  animation-fill-mode: backwards;
  animation-delay: .25s; /* how long to delay the animation from starting */
  animation-iteration-count: 1; /* how many times the animation will play */
  animation-name: videoThumbLoadAnimation; /* the name of the animation we defined above */

  .fyseevideo__tt--image {
    max-height:70%;
    width:auto;
  }

}


.fyseeplaylist__name--small {
  font-size: 1rem;
  font-weight:$font-bold;
}
.fyseeplaylist__name--large {
  font-size: 1.3rem;
}

.fyseebtn {
  font-size:.6rem;
  font-weight: $font-bold;
  text-transform: uppercase;
  color:$nevada!important;
  border: 1px solid $nevada;
  padding: 4px 6px;
  cursor: pointer;
  @include transition-all(.2s);
  &:hover {
    color:$white!important;
    border-color:$blade;
    background-color:rgba(255,255,255,.1);
  }
}

.fyseesignup__btn {
    transform:scale(.8)!important;
    transform-origin: left top!important;
}

.fyseereturn__btn {
  color:$white!important;
}


.playlistselector__container {
  position: relative;


  .playlistselector__gradient {
    display: none;
    background: rgb(21,22,22);
    background: linear-gradient(90deg, rgba(21,22,22,0) 0%, rgba(21,22,22,1) 95%);
    width:15%;
    position: absolute;
    top:0px;
    right:0px;
    z-index:1;
    height:100%;
    pointer-events: none;
  }

  .playlistselector__scroll {
    overflow:visible;
    // overflow-x:scroll;
  }
}




.fyseelogo__small {
  max-width:120px;
}
.nflogo__small {
  max-width:10px;
}



.overlay__container {
  position: fixed;
  z-index:1111111;

  width:100%;
  height:100%;
  top:0px;
  left:0px;
  display:flex;
  justify-content: center;
  align-items: center;
  padding:30px;

  .overlay__inner {
    background-color:$black;
    width:100%;
    max-width:1000px;
    height:100%;
    position: relative;
    z-index:2;
  }

  .overlay__bg {
    background-color:rgba(0,0,0,.9);
    position: absolute;
    z-index:1;
    width:100%;
    height:100%;
    top:0px;
    left:0px;
    cursor: default;
  }

}

.singlevideo__container {

  padding:20px;

  .singlevideo__header {

  }
  .singlevideo__body{
    position: relative;
    overflow:hidden;

    .singlevideo__scroll {
      position: absolute;
      padding-bottom:10%;
      top:0px;
      left:0px;
      height:100%;
      overflow:hidden;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      width:calc(100% + 20px);
      padding-right:20px;
    }

    .singlevideo__desc {

      font-size:.9rem;
      line-height:1.2em;
      color:$stone;
      margin-top:20px;

    }

    .singlevideo__gradient {
      z-index:1;
      position: absolute;
      bottom:0px;
      height:20%;
      width:100%;
      left:0px;
      background: rgb(17,17,17);
      background: linear-gradient(180deg, rgba(17,17,17,0) 0%, rgba(17,17,17,1) 95%);
      pointer-events: none;
    }



  }




  .singlevideo__nominations {

    text-align: center;


    .titledetail__nominations--campaign-title {
      color:$white;
      font-size: 10px;
      text-transform: uppercase;
      font-weight: $font-light;
      margin: 40px 0;
      opacity: 1;
      letter-spacing: .2rem;
    }

    .titledetail__nominations--award-container {
      // border-bottom: 1px solid #333;
      // padding-bottom:20px;
      margin-bottom:40px;
      &:last-of-type {
        margin-bottom: 40px;
      }
    }
    .titledetail__nominations--award-title {
      color:$stone;
      font-size:12px;
      text-transform: uppercase;
      font-weight:$font-med;
      width: 100%;
      letter-spacing: .1rem;
      opacity: .75;
      line-height: 1rem;
    }
    .titledetail__nominations--name {
      color:$white;
      // font-size:14px;
      font-weight:$font-med;
      // margin-top: 5px;
      line-height:1.35em;
      text-align: center;
    }

    .awards-rule {
      width:30%;
      opacity:.2;
      margin:0px auto;
      border:0px;
      // border-top:1px solid $white;
      height:1px;
      margin-top:15px;
      background: rgb(255,255,255);
      background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 25%, rgba(255,255,255,1) 75%, rgba(255,255,255,0) 100%);
    }



  }





}








@media (min-width: $break-lg) {

  .playlistselector__container {
    position: relative;
    overflow: hidden;
    bottom: 40px;

    .playlistselector__gradient {
      display:block;
      z-index:11;
    }

    .playlistselector__scroll {
      overflow:hidden;
      overflow-x:scroll;
      -webkit-overflow-scrolling: touch;
      bottom: -37px;
      padding-bottom: 30px;
      position: relative;


    }
  }

  .fyseecomingsoon__container {

    .fyseecomingsoon__shadow-bottom {
      display: block;
    }

    .fyseecomingsoon__scroll {

      // padding-top:30px;
      height:100%;
      overflow:hidden;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      position: absolute;
      top:0px;
      left:0px;
      padding-bottom:20%;
      width:calc(100% + 20px);
    }
  }


  .fyseehero__playlist-relatedvideos {
    overflow:hidden;
  }


  .fyseehero__playlist-scrollcontainer {

    height:calc(100% + 20px;);
    padding-bottom:20px;

    // position: relative;

    .fyseehero__playlist-scroll {
      position: static;
      // padding-right:20%;
      overflow:hidden;
      overflow-x: scroll;
      -webkit-overflow-scrolling: touch;
    }
    .fyseehero__playlist-scroll-padding {
      padding-right:20%;
    }

    .fyseehero__playlist-scrollshadow {
      display: block;
      width:20%;
      height:100%;
      position: absolute;
      bottom:0px;
      right:0px;
      background: rgb(21,22,22);
      background: linear-gradient(90deg, rgba(21,22,22,0) 0%, rgba(21,22,22,1) 95%);
    }

  }




}






@media (min-width: $break-xl) {





  .fyseehero__playlist-scrollcontainer {

    width:calc(100% + 20px;);
    height:auto;
    padding-right:20px;
    padding-bottom:0px;

    // position: relative;

    .fyseehero__playlist-scroll {

      position: absolute;
      top:0px;
      left:0px;
      width:100%;
      height:100%;
      overflow:hidden;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      // padding-bottom:20%;
    }
    .fyseehero__playlist-scroll-padding {
      padding-bottom:20%;
      padding-right:20px;
    }

    .fyseehero__playlist-scrollshadow {
      display: block;
      width:100%;
      height:20%;
      position: absolute;
      bottom:0px;
      right:0px;
      background: rgb(21,22,22);
      background: linear-gradient(180deg, rgba(21,22,22,0) 0%, rgba(21,22,22,1) 95%);

    }

  }




}









@media (min-width: $pageMaxWidth) {

  .hero__container {
    .hero__content {
      padding: 75px 0px 0px;
    }
  }
}
