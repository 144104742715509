// Imports

@import 'reset',
        'fonts',
        'colors',
        'variables',
        'mixins',
        'forms',
        'buttons',
        'screeners',
        'titledetail';

// Root

.scroll-container::-webkit-scrollbar,
.scroll-container::-webkit-scrollbar {
  width: 3px!important;
  background-color: transparent!important;
}

.scroll-container::-webkit-scrollbar-track {
  box-shadow:none!important;
  border:none!important;
  width: 5px!important;
  background-color: transparent!important;
}

.scroll-container::-webkit-scrollbar-corner,
.scroll-container::-webkit-scrollbar-track-piece,
.scroll-container::-webkit-resizer {
  box-shadow:none!important;
  border:none!important;
  background-color: transparent!important;
}

.scroll-container::-webkit-scrollbar-thumb,
.scroll-container::-webkit-scrollbar-thumb:horizontal,
.scroll-container::-webkit-scrollbar-thumb:window-inactive {
  box-shadow:none!important;
  border:none!important;
  //background-color: rgba(255,255,255,.15)!important;
  background-color: transparent!important;
  outline: 0!important;

}

.scroll-container::-webkit-scrollbar-thumb,
.scroll-container::-webkit-scrollbar-thumb:horizontal,
.scroll-container::-webkit-scrollbar-thumb:window-inactive {
  box-shadow:none!important;
  border:none!important;
  background-color:transparent!important;
  outline: 0!important;
  transition:all .2s ease;
}
.scroll-container:hover::-webkit-scrollbar-thumb,
.scroll-container:hover::-webkit-scrollbar-thumb:horizontal,
.scroll-container:hover::-webkit-scrollbar-thumb:window-inactive {
  background-color: rgba(255,255,255,.15)!important;
}


*:focus {
  border-color: inherit!important;
  outline:none!important;
  -webkit-box-shadow: none!important;
  box-shadow: none!important;
}

#root {
  display:flex;
  flex-direction: column;
  // height:100%;
}


html, body {
  height:100vh;
  background-color:$midnight;
  color:$white;
  overscroll-behavior: none;

}

a {
  color:$nflxred;
  text-decoration: none;

  &:visited,
  &:hover {
    color:$nflxred;
    text-decoration: none;
  }

  &:active,
  &:focus {
    color:$white;
    text-decoration: none;
  }
}

img {
  max-width:100%;
}

strong {
  font-weight:$font-bold;
}

.font-weight-bold {
    font-weight: 600;
}

.width_ctrl {
  width:100%;
  margin:0px auto;

}

.bar--red {
  height:1px;
  background: rgb(229,9,20);
  background: linear-gradient(90deg, rgba(229,9,20,0) 0%, rgba(229,9,20,1) 25%, rgba(229,9,20,1) 75%, rgba(229,9,20,0) 100%);
  margin:0px auto;
  @include transition-all(.3s);
}

.bar--white--left {
  height:1px;
  @include gradient(255, 90deg, 1, 0, 0%, 100%);
  margin:0px;
  @include transition-all(.3s);
}

.text-red {
    color:#e50914!important;
}




.header__container {

   //background-color:rgba(0,0,0,.5);
  transition: background-color .35s ease;

  z-index:11;
  position: fixed;
  top:0;
  left:0;
  width:100%;


  .header__sticky {
    height:$headerHeight;
    // padding:0px 15px;
    // position: fixed;
    // z-index: 30;
    width:100%;
    // background-color:rgba(0, 0, 0, .75);
    //position: sticky;
    //top:0;
  }

  .header__title {
    span {
      font-size:10px;
      text-transform: uppercase;
      display: block;
    }
    .header__title--main {
      font-weight:$font-bold;
      margin-bottom:3px;
    }
    .header__title--tagline {
      color:$white;
      font-size:8px;
    }
  }

  .header__logo-wrapper {
    // flex: 0 1 50px;
    // flex-grow:0;
    margin-right:1rem;
    .header__logo {
      // max-width: 20px;
      height:36px;
      cursor: pointer;
    }
  }

  .header__buttons-container {
    .header__buttons {
      font-size:12px;
      &.header__buttons--mobile {

      }
      &.header__buttons--desktop {
        flex:1;
      }
      .button__fysee {

      }
      .button__redeem-code {

      }
    }
  }

}




@keyframes heroPageLoadAnimation {
  0% {
    // transform: translateX(-100%);
    opacity:0;
    transform: scale(0.925)
  }
  100% {
    // transform: translateX(0);
    opacity:1;
    transform: scale(1)
  }
}
@keyframes heroPageLoadAnimationText {
  0% {
    // transform: translateX(-100%);
    opacity:0;
    transform: translateY(20px);
  }
  100% {
    // transform: translateX(0);
    opacity:1;
    transform: translateY(0px);
  }
}


.hero__container {

  max-width:$pageMaxWidth;
  margin:0px auto;

  min-height:$heroHeight;
  height:$heroHeight;
  position: relative;
  z-index:0;
  width:100%;
  overflow: hidden;
  @include transition-all(.3s);

  &.hero__container--hidden {
    min-height:$headerHeight;
    height:$headerHeight;
  }

  .hero__background {
    width: 100%;
    height:100%;
    position: absolute;
    top:0px;
    left:0px;
    opacity:.15;
    // background-image:url(../img/hero-sample.jpg);
    background-position: center top;
    background-size:cover;
    background-repeat: no-repeat;

    .hero__background-shadow {
      // background: #ccc;
      flex:0 0 30%;
      position: relative;
      z-index:1;
      &.hero__background-shadow--left {
        background: rgb(21,22,22);
        background: linear-gradient(90deg, rgba(21,22,22,1) 5%, rgba(21,22,22,0) 100%);
      }
      &.hero__background-shadow--right {
        background: rgb(21,22,22);
        background: linear-gradient(90deg, rgba(21,22,22,0) 0%, rgba(21,22,22,1) 95%);
      }
    }

    .hero__video {
      position: absolute;
      top: 50%;
      left: 50%;
      min-width: 100%;
      transform: translate(-50%, -30%);
    }

  }

  .hero__content {
    min-height:$heroHeight;
    height:$heroHeight;
    position: relative;
    z-index:1;
    flex:100%;
    padding: $headerHeight 20px 0px;
    // opacity:0;

    animation: 4s ease-out 0s 1 heroPageLoadAnimation;


    .hero__titletreatment {

      width:280px;

      animation-duration: 1s; /* the duration of the animation */
      animation-timing-function: ease-out; /* how the animation will behave */
      animation-delay: 0s; /* how long to delay the animation from starting */
      animation-iteration-count: 1; /* how many times the animation will play */
      animation-name: heroPageLoadAnimation; /* the name of the animation we defined above */

    }

    .hero__description {

      animation: 1.25s ease-out 0s 1 heroPageLoadAnimation;

    }

    .hero__buttons {

      .button__redeem-code  {
        animation: .7s ease-out 0s 1 heroPageLoadAnimationText;
      }
      .button__watch-now  {
        animation: 1s ease-out 0s 1 heroPageLoadAnimationText;
      }

    }

  }

}


.quote {
  font-size:100%;
  line-height:1.5em;
  .quote__name {
    display:block;
    font-size:9px;
    font-weight:$font-bold;
    text-transform: uppercase;
  }
}




.dropdown__container {

  cursor: pointer;

  .dropdown__select {
    .dropdown__label {
      font-weight:$font-bold;
      font-size: 12px;
    }
    .dropdown__arrow {
      opacity:.3;
      @include transition-all(.3s);
    }
  }

  .dropdown__content {

    min-width:280px;
    pointer-events:none;
    opacity:0;
    background-color: $black;
    @include arrowbox($shark, $black);
    @include box-shadow(0px,0px,30px,rgba(0,0,0,.3));
    @include transition-all(.1s);
    position: absolute;
    top: 95%;
    left: 50%;
    transform: translateX(-50%);
    padding: 15px;

    li {

      line-height:1.5em;
      text-align:left;

    }

    .dropdown__item {
      opacity:.6;
      color:$white;
      font-size:14px;
      &:hover {
        opacity:1;
      }
      &.dropdown__item--selected {
        opacity:1;
        font-weight:$font-bold;
      }
    }
  }

  &.active {
    .dropdown__content {
      pointer-events:all;
      opacity:1;
      top: 150%;
    }
  }

}


.subnav__container--absolute {
  position:fixed;
  top:0;
  left:50%;
  transform:translateX(-50%);
  z-index:11;
  height:75px;
}


.subnav__container--return {
  opacity:.5;
  transition: all .2s ease;
  // font-weight:bold;
  color:$white;
  font-size:14px;
  &:hover {
    color:$white;
    opacity:1;
  }
}

.subnav__container {
  // padding:0px 15px;
  // position: absolute;
  z-index: 20;
  top:0px;
  width:100%;
  // background-color:rgba(0, 0, 0, .3);
  @include transition-all(.3s);
  // height:1px;
  // opacity: 0;
  // pointer-events:none;


}

.main__container.main__container--active {
  padding-top:$headerHeight;
  .bar--red {
    position: fixed;
    z-index:30;
    top:$headerHeight;
  }
  .subnav__container {
    height:$headerHeight;
    opacity: 1;
    top:$headerHeight;
    position: fixed;
    pointer-events:all;
  }
}





.search__container {
  position: relative;
  z-index: 2;
  margin:25px 0 10px;

  .search__form {
    padding:0px 20px;

    .button__clear {
      opacity:0;
      position: absolute;
      top:10px;
      right:20px;
      text-transform: uppercase;
      font-size:9px;
      font-weight:$font-bold;
      z-index:1;
      pointer-events: none;

      &.button__clear--searching {
        pointer-events:all;
        opacity:.3;
        &:hover {
          opacity:1;
        }
      }

    }

  }
  input {
    flex:1;
  }
}

.searchresults__container {
  // display:none;
  position: relative;
  z-index:2;
  padding:20px 40px 40px;

  &.searchresults__container--searching {
    display:flex;
  }

  .searchresults__genre-title {
    font-size:10px;
    font-weight:$font-bold;
    text-transform: uppercase;
    color:$white;
    opacity:.25;
    padding:20px 0px 10px;
    line-height: 1.2;
  }

  a {
    color:$white;
  }

  li {
    font-size:90%;
    line-height:1.5em;
  }

}




.text-small {
  font-size:.6em;
}

.page__background {
  opacity:.1!important;
}


.main__container {
  padding: 0px;
  position: relative;
  //flex:1;
  //background-color:#151313;
  //top:0px;
  //display: flex;
  //flex-direction: column;
  // @include box-shadow(0px,0px,50px,rgba(0,0,0,1));


  .main__content {
    position: relative;
    z-index:3;
  }

  .main__background {
    z-index: 0;
    position: fixed;
    pointer-events: none;
    top:0;
    left:0;
    // top:50%;
    // left:50%;
    // transform: translate(-50%,-50%);
    width:100vw;
    height:100vh;
    opacity:.75;
    transition: opacity .5s ease-in, filter .5s ease-in;
    background-image:url(../img/bg/bg-streams-24.png);
    background-size: cover;
    background-position: right center;
    background-repeat: repeat-y;

    &.events__background {
      opacity:.1;
    }


    &.no-default {
        background-image:none;
    }

    filter:blur(10px);

    &.active {
      opacity:.05;
      filter:blur(10px);
    }
  }

  .fysee__background {
    z-index: 1;
    position: fixed;
    pointer-events: none;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    width:115%;
    height:115%;
    opacity:.15;
    transition: all .5s ease-in;
    // background-image:url(../img/bg/bg-streams4.png);
    background-position: center top;
    background-size:cover;
    background-repeat: no-repeat;

    // filter:blur(10px);

    &.active {
      opacity:.05;
      filter:blur(10px);
    }
  }

  .fysee__content {

    transition: all .5s ease-in;
    &.active {
      opacity:.2;
      filter:blur(10px);
    }
  }

}



.main__background--info {
  position: absolute;
  z-index:22;
  top:50%;
  right:5rem;
  transform:translateY(-50%);
  opacity: 1;
  transition:all .2s ease;
  padding: 10rem 0 10rem 10rem;

  .main__background--info-tt {
    max-width:300px;
    max-height:300px;
  }

  &.active {
    opacity:.05;
    z-index:0;
  }
}


.main__background--controls {
  position: relative;
  // bottom:1rem;
  // right:1rem;
  text-align: center;
  width:100%;
  z-index:33;
  cursor: pointer;
  opacity:.6;
  transition: all .2s ease;
  margin-top: 3rem;
  margin-bottom: 6rem;

  &:hover {
    opacity:1;

    .main__background--controls-tip {
      opacity:1;
    }
  }

  .main__background--controls-tip {

    transition: all .5s ease;
    pointer-events: none;
    color:#000;
    padding:.75rem;
    font-weight:600;
    font-size:.8rem;
    text-transform: uppercase;
    position: absolute!important;
    white-space: nowrap;
    top:-120%;
    left:50%;
    transform:translateX(-50%);
    opacity: 1;
  }

}

#mainBackgroundVideo {
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  min-height:100%;
  min-width: 100%;

}

.spacer { width:100%;}



.content__container {
  position: relative;
  z-index:2;
  // min-height: 100vh;
}


.white-overlay {
  filter: brightness(0) invert(1)!important;
}


.sidebar__container {

  // padding-top:2rem;
  // min-height: 100vh;
  // position: relative;
  // z-index:2;

  .sidebar__container--inner {

    min-height: 100%;
    padding:6rem 0;

    .sidebar__container--list  {

      transition: all .5s ease-in;

      li {

        line-height:2em;
        position: relative;
        z-index:0;


        > a  {
          // line-height:1.25em;
          font-size:.7rem;
          color:#fff;
          font-weight:200;
          transition: all .5s ease-out;
          display: block;
          // opacity: .6;
          text-transform: uppercase;
          line-height: 1.2;
          letter-spacing: .3em;
          padding:1.35rem 0;
          &:hover {
            opacity: 1;
          }
        }




        .sidebar__list--flyout {
          position: absolute;
          top:50%;
          transform: translateY(-50%);
          left:95%;
          opacity: 0;
          transition:all .2s ease-out;
          // pointer-events: none;
          width:auto;

          .sidebar__list--flyout-inner {

            display: flex;
            align-items: center;
            padding:6rem;
            padding-left:0rem;
            width:80vw;

            .sidebar__list--flyout-item {
              opacity: 0;
              transition:all .5s ease-out;
              // width:100px;
              // height:140px;
              // background: #fff;
              // opacity:0;
              padding:0 1.5rem;
              display: flex;
              align-items: center;



              &:nth-child(1) {transition-delay: 0s;}
              &:nth-child(2) {transition-delay: .15s;}
              &:nth-child(3) {transition-delay: .3s;}
              &:nth-child(4) {transition-delay: .45s;}
              &:nth-child(5) {transition-delay: .6s;}

              .sidebar__list--flyout-image {
                width:100%;
                max-height:100px;
                max-width:200px;

                // box-shadow:0px 0px 0px rgba(0,0,0,.8);
              }
            }

          }
        }

        &:hover {

          z-index:11;

          .sidebar__list--flyout {
            opacity: 1;
            // pointer-events: all;
            .sidebar__list--flyout-inner {

              .sidebar__list--flyout-item {
                opacity: 1;
                // transition:all .2s ease-out;
              }

              &:hover {
                .sidebar__list--flyout-item:not(.sidebar__list--flyout-item-seeall) {
                  opacity:.5;
                  &:hover {
                    opacity:1;
                    transform:scale(1.1,1.1);
                    .sidebar__list--flyout-image {
                      // box-shadow:0px 0px 30px rgba(0,0,0,.8);
                    }
                  }
                }
              }

            }
          }
        }

      }

      &:hover li {

          > a {
            opacity: .5;
            // font-size:.65rem;
            // filter: blur(2px);
            &:hover, &.active {
              opacity: 1;
              filter: blur(0px);
              // font-size:.65rem;
            }
          }


      }

    }

  }
}

.sidebar__container.sidebar__container--mobile {
  .sidebar__container--inner {

    padding:2rem 0;

    .sidebar__container--list  {
      li {

        > a {
          text-align: center;
          margin-bottom:1rem;
        }
        .sidebar__list--flyout {
          display: none;
          opacity:1;
          position: relative;
          top: auto;
          left: auto;
          transform: none;
          margin:2rem 0;
          width:100%;
          background: rgba(255,255,255,.1);
          // background: #222;
          // padding-bottom: 2rem;

          &:after {
            bottom: 100%;
          	left: 50%;
          	border: solid transparent;
          	content: "";
          	height: 0;
          	width: 0;
          	position: absolute;
          	pointer-events: none;
          	border-color: rgba(34, 34, 34, 0);
          	border-bottom-color: rgba(255,255,255,.1);
          	border-width: 20px;
          	margin-left: -20px;
          }


          &.active {
            display:block;
          }

          .sidebar__list--flyout-inner {
            flex-direction: row;
            padding: 0;
            flex-wrap: wrap;
            justify-content: center;
            width:100%!important;


            .sidebar__list--flyout-item {
              opacity: 1;
              padding:2rem;
              .sidebar__list--flyout-image {
                max-height:150px;
                max-width:150px;
                display: block;
                margin:0 auto;
              }
              &.sidebar__list--flyout-item-seeall {
                padding:2rem!important;
              }
            }
          }
        }
      }
      &:hover {
        li {
          > a {
                filter: blur(0px);
          }
        }
      }
    }
  }
}

.social-icon {
  max-width:20px;
}
.searchresult {
  transition: all .2s ease;
  background-color: rgba(255,255,255,.05);
  line-height:1.25em;
  color: #fff;
  &:hover {
    background-color: rgba(255,255,255,.1);
  }
}






.titledetail__nominations--competition-title {
  font-size:.8rem;
  text-transform: uppercase;
  letter-spacing:.15rem;
  color:#999;
  font-weight: 600;
  line-height: 1.3em;
}

.awards-rule {
  width:30%;
  opacity:.2;
  margin:0rem auto;
  border:0px;
  border-top:1px solid $white;
  height:1px;
  // margin-top:15px;
  // background: rgb(255,255,255);
  // background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 25%, rgba(255,255,255,1) 75%, rgba(255,255,255,0) 100%);
}

.competition_name {
    font-size: .7rem;
    letter-spacing: .15rem;
    border-bottom: 1px solid rgba(255,255,255,.3);
    border-top: 1px solid rgba(255,255,255,.3);
    color: #fff;
    padding: 15px 0px;
    text-transform: uppercase;
    display: inline-block;
}


.nomineelist__award {
  font-size:.6rem;
  text-transform: uppercase;
  letter-spacing:.15rem;
  color:#999;
}
.nomineelist__nominee {
  text-transform: uppercase;
  letter-spacing:.05rem;
  line-height: 1em;
  .nomineelist__nominee--award {
    font-size:.8rem;
  }
  .nomineelist__nominee--person {
    font-weight: bold;
    font-size:1rem;
    text-transform: uppercase;
    letter-spacing: normal;
    color:#fff;
    line-height: 1.35em;
  }
  .nomineelist__nominee--rolename {
    // text-transform: none!important;
  }
  .nomineelist__nominee--character {
    font-size:.6rem;
    line-height: 2em;
  }
}



.sidebar__scrollcontainer--outer {

  position: relative;
  z-index:2;
  height:auto;

  .sidebar__scrollcontainer--inner {
    position: relative;

    .sidebar__scrollcontainer--content {
      // height:6000px;
      // background:rgba(255,255,255,.1);
    }

  }
}



.sidebar__container--titlepage {

  position: relative;
  z-index:3;
  height:auto;

  .sidebar__container--inner {

      // min-height: 100%;
      position: relative;
      z-index:2;


      .sidebar__container--nomineelist-wrapper {

        position: relative;
        z-index:2;
        // overflow: hidden;


        .sidebar__container--shadow {
          width:100%;
          position: absolute;
          bottom:0px;
          z-index:2;
          pointer-events: none;
          img {
            display: block;
          }
        }

        .sidebar__container--nomineelist {
          position: relative;
          top:0px;
          bottom:0px;
          width: 100%;
          overflow-x: hidden;
          overflow-y: scroll;
          text-align: center;

          transition: all .5s ease;
          opacity: 1;

          &.hidden {
            opacity: .08;
          }



        }

      }


      .sidebar__container--explore-toggle {
        // z-index:3;
        // position: absolute;
        // bottom:0px;
        // width: 100%;

        // padding-bottom:2rem;

          position: relative;
          z-index: 3;



        .sidebar__container--explore-toggle-link {

          display: block;
          text-align: center;
          font-size:.85rem;
          letter-spacing:.3rem;
          text-transform:uppercase;
          color:#fff;
          font-weight:200;
          transition: all .5s ease-out;
          padding: 0;
          // background-color: rgba(255,255,255,.2);
          opacity: 1;
          border-radius: 4px;
          position: relative;
          z-index: 111;


          // &:hover,  &.active {
          //   background-color: rgba(255,255,255,.35);
          //   // color:rgba(255,255,255,1);
          //   // opacity: 1;
          //   transform:translateY(-5px);
          // }

          .sidebar__container--explore-toggle-arrow {
            transition: all .2s ease;
            &.active {
              transform:rotateX(180deg);
            }
          }

          .sidebar__container--explore-icon {
            transition: all .2s ease-out;
            position: relative;
            opacity: .65;
            cursor: pointer;
            color:#fff;
            border-radius:4px;
            padding:.5rem;
            background:transparent;

            .sidebar__container--explore-icon-label {
                letter-spacing:normal;
                font-size: .7rem;
                margin-top: 0.3rem;
                font-weight: bold;
            }

            .sidebar__container--explore-icon-tip {

              transition: all .2s ease-out;
              position: absolute;
              top:-150%;
              left:50%;
              transform:translateX(-50%);
              font-size:1rem;
              color:#333;
              border-radius:4px;
              padding:1rem;
              opacity:0;
              pointer-events: none;
              font-size: .8rem;
              white-space: nowrap;
              line-height: 1.25em;

            }
            &:hover {
              opacity: 1;
              .sidebar__container--explore-icon-tip {
                opacity:1;
              }
            }
            &.active {
              opacity: 1;
              color:#fff;
              background:#e50914;
            }
          }


        }

      }


      .sidebar__container--navlist  {

        // position: relative;
        // z-index:3;
        // position: absolute;
        // top:0px;
        // bottom:0px;
        // width: 100%;

        transform:translateY(100%);
        transition: all .75s ease;
        opacity: 0;

        &.active {
          transform:translateY(0%);
          opacity: 1;
        }

        li {

          line-height:2em;
          position: relative;
          z-index:0;


          > a  {

            text-align: center;
            color:#fff;
            transition: all .5s ease-out;
            display: block;
            padding:.75em 0;
            font-size:1rem;
            font-weight:200;
            text-transform: uppercase;
            line-height: 1.2em;
            letter-spacing: .35em;

            &:hover {
              opacity: 1;
            }
          }
        }

      }
  }
}



.arrowbox__grey {
	position: relative;
	background: #f9f9f9;
}
.arrowbox__grey:after {
  top: 100%;
	left: 50%;
	border: solid transparent;
	content: "";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(249, 249, 249, 0);
	border-top-color: #f9f9f9;
	border-width: 7px;
	margin-left: -7px;
}




.titlepage__social--icon {
  max-width:40px;
}

.titledetail__mediaitem {
  color:#fff;
  &:hover {
    color:#fff;
  }
}

.titledetail__medialistitem {
    border-top:1px solid #444;
    transition: all .2s ease;
    color:#fff!important;

    &:last-of-type {
        border-bottom:1px solid #444;
    }

    &:hover {
        background-color:rgba(255,255,255,.02);
    }
}


.sidebar__container--ctalist {

  .sidebar__list--separator {
    border-bottom:1px solid #444;
    padding-top: .5rem;
    margin-bottom: .75rem;
  }

  opacity:.6;
  transition: all .5s ease-out;

  &:hover {
    opacity:1;
  }

  &.active {
    opacity:.2;
    &:hover {
      opacity:1;
    }
  }

  .sidebar__list--cta {

    .sidebar__list--ctamig {
      max-height:1.3rem;
      display: inline-block;
    }

    > a  {
      font-size:1.45rem;
      color:#fff;
      font-weight:200;
      transition: all .5s ease-out;
      display: block;
      margin:0 .2rem;
    }

    .sidebar__list--cta-description {
      p {
        font-size:.8rem;
        line-height:1.35em;
        padding-left:.2rem;
        padding-top:.5rem;
      }
    }
  }
}

.video-indicator {
  // background-color:rgba(255,255,255,.2);
  width:100%;
  margin:0 auto;
  position: fixed;
  bottom:0px;
  left: 0px;
  z-index:900;
}

.video-bar {
  height:3px;
  background-color:#e50914;
  transition:width .2s ease-out;
  width:0px;
}

.main__gradient {
  position: fixed;
  top:0;
  left:0;
  width:50vw;
  height:100%;
  z-index:1;
  background: rgb(0,0,0);
  background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);

  &.main__gradient-home {
    background: linear-gradient(90deg, rgba(21,19,19,1) 0%, rgba(21,19,19,0) 100%);
  }
}

.btn__see-all {
  font-size:.7rem;
  color: #e50914;
  background-color: transparent;
  border: 1px solid #e50914;
  font-weight: 600;
  text-transform: uppercase;
  padding: 0 .5rem;
  line-height: 2.75em;
  position: relative;
  box-shadow: 0 0 0 transparent;
  overflow: hidden;
  white-space: nowrap;
  transition:all .2s ease;
  &:hover {
    color:#fff;
    background-color: #e50914;
  }
}


.titlepage__logo--img {

}


.titlepage__trailer--container {
  opacity:0;
  transition:all .5s ease;
  pointer-events: none;
  &.active {
    opacity: 1;
    pointer-events: all;
    position: relative;
    z-index:5;
  }
}

.homepage__cta--container {

  // position: absolute;
  // bottom:6rem;
  // // left:0px;
  // right:0px;
  // width:30%;
  // width:100px;
  // height:30px;
  z-index:111;
  transition:all .5s ease;
  opacity:1;

  &.active {
    opacity:.3;
  }


  &:hover {

    opacity:1;
  }
  .homepage__cta {
    // background: #ccc;

    .homepage__cta--item {

      cursor: pointer;
      transition:all .5s ease;
      // background-color: rgba(255,255,255,.15);
      // padding:1rem;

      &:hover {
        // background-color: rgba(0,0,0,.5);
        transform: scale(1.05,1.05);
        opacity:1;
      }

      .homepage__cta--item-title {
        font-size: 1.2rem;
        line-height:1em;
      }
      .homepage__cta--item-desc {
        font-size: .7rem;
        line-height:1.3em;
        max-width:240px;
      }
    }

  }


}


.search__container {
  position: fixed;
  top:0px;
  left:0px;
  width:100%;
  height:100vh;
  z-index:1111;
  margin:0px;
  background-color:rgba(0,0,0,.9);
}







.genre__container {



  .genre__tier {

    flex:100%;
    @include transition-all(.5s);
    opacity:1;

    &.genre__tier--two  {
      // margin-top:30px;
      // padding-top:30px;
      // border-top:1px solid $shark;
    }

    &.genre__tier--active  {
      .titleart__panel-container {
        .titleart__panel {
          .titleart__panel-content {
            opacity:.25;
          }
          .titleart__panel-categorylabel {

            opacity:.25;
          }
        }
        &.titleart__panel--mousedover {
          .titleart__panel {
            .titleart__panel-content {
              opacity:1;

            }
          }
        }
      }
    }

    .titleart__panel-container {

      padding:5px;
      padding-bottom:20px;

      .titleart__panel {

        position: relative;

        .titleart__panel-content {

          overflow: hidden;
          @include center-center;
          width:100%;
          height:100%;
          z-index:1;
          @include transition-all(.5s);
          // cursor:pointer;
          // background-color:$shark;
          @include box-shadow(0px,0px,0px,rgba(0,0,0,.3));

          .titleart__image {
            @include center-center;
            // @include transition-all(.3s);
            z-index:1;
            animation-duration: 1s; /* the duration of the animation */
            animation-timing-function: ease-out; /* how the animation will behave */
            animation-delay: 0s; /* how long to delay the animation from starting */
            animation-iteration-count: 1; /* how many times the animation will play */
            animation-name: titleartImageLoadAnimation; /* the name of the animation we defined above */
          }



          .titleart__panel-content--inner {

            @include transition-all(1s);
            opacity: 0;
            transform: scale(.9);
            width:100%;
            height:100%;

          }

        }

        .titleart__panel-categorylabel {
          position: relative;

          color: #fff;
          font-size: .4rem;
          opacity:1;
          @include transition-all(1s);

        }

      }


      // panel container hover
      &:hover {
        .titleart__panel {
          .titleart__panel-content {
            z-index:5;
            // background-color:$black;
            width:103%;
            height:103%;
            @include box-shadow(0px,0px,70px,rgba(0,0,0,.3));

            .titleart__panel-content--inner {
              opacity:1;
              transform: scale(1);
              z-index:2;

            }

          }

          .titleart__panel-categorylabel {
            opacity:1;
          }

        }
      }

    }

  }


}

.titledetail__background--blurred {
  filter:blur(10px)!important;
  opacity:.2!important;

}




.videoplaceholderblank {

}

.video__container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  // z-index:-1;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // z-index:1;
  }
}

.video__container--placeholder {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  width:100%;
  display:none;
}


/* events */

.event__excerpt--home-dates {
    .event__excerpt--date-day {
      font-size:2rem;
          font-weight: 400;
          color: #fff!important
    }
    .event__excerpt--date-dayname {
      font-weight: 600;
      text-transform: uppercase;
      font-size:10px;
      color: #fff!important
    }
    .event__excerpt--date-month {
      font-weight: 600;
      font-size:.6rem;
      text-transform: uppercase;
      color: #fff!important
    }
}


.event__excerpt--container {

  .event__excerpt--top {
    position: relative;

    a, a:hover {
      color:$white!important;
    }

    .event__excerpt--top-inner {
      position: absolute;
      top:0px;
      left:0px;
      width:100%;
      z-index:1;
      text-align: center;


      .event__excerpt--date-day {
        font-size:5rem;
            font-weight: 400;
      }
      .event__excerpt--date-dayname {
        font-weight: 600;
        text-transform: uppercase;
        font-size:12px;
      }
      .event__excerpt--date-month {
        font-weight: 600;
        font-size:1rem;
        text-transform: uppercase;
      }



    }

    .event__excerpt--top-shadow {
      position: absolute;
      top:0px;
      right:0px;
      width:120%;
      z-index:0;
      opacity: .85;
    }

    .eventthumbnail__spacer {
      width:100%;
      height:auto;
    }
  }

  .event__excerpt--bottom {
    background: rgba(255,255,255,.03);
    min-height:auto;
  }
  .event__excerpt--detail-container {
    background: rgba(0,0,0,.5);
    min-height:auto;
  }


}

.event__excerpt--title {
  font-size: 1.5em;
    line-height: 1.2em;
    font-weight: 600!important;
    a, a:hover {
      color:$white!important;
    }
}
.event__excerpt--title-large {
  font-size: 2.5em;
    line-height: 1.2em;
}
.event__excerpt--tags-container {
  .event__excerpt--tag {
    font-size: .8rem;
    text-transform: uppercase;
    font-weight: 600;
    background: #151515;
    padding: 7px 7px;
    border-radius: 3px;
    display: inline-block;
    margin-right:7px;
  }
}
.event__excerpt--venue {
  color:$white;
  background-color:rgba(255,255,255,.1);
  padding:.5rem;
  font-size:.8rem;
  border-radius:3px;
}
.event__excerpt--participants {
  color:$stone;
}

.event__excerpt--description {
  font-size:1rem;
  line-height:1.35rem;
  overflow:hidden;

  p {
    margin-bottom:15px;
  }

  em {
    font-style: italic;
  }
  ul {
    display: block;
    margin-bottom:1em;
    margin-top:1em;
    padding-left:1rem;
    li {
      list-style:outside;
      margin-bottom:.5rem;
    }
  }

}

.event__excerpt--show {
  font-size:.8rem;
  font-weight:300;
  text-transform: uppercase;
  padding-bottom:1rem;
  a {
    color:#AAAAAA!important;
  }
}


.eventmodal__container {
  .modal-content {
    background-color:$thunder;
    .modal-header {
      border:none!important;
      button {
        color: #fff;
        text-shadow: none;
        font-weight: 300;
        font-size: 2rem;
      }
    }
    .modal-footer {
      border:none!important;
    }
  }
}


.recycle-image {
  max-width: 100px;
}


.titledetail__feed-container {
    .titledetail__feed-title {

      display: flex;
      align-items: center;
      margin-bottom: 3rem;
      opacity: .7;

      .line {
        flex:1 1 auto;
        border-bottom:1px solid rgba(255,255,255,.2);
        height:1px;
      }
      .title {
        text-transform: uppercase;
        text-align: center;
        letter-spacing: .3em;
        font-size: 14px;
        padding:0 1rem;
        color:rgba(255,255,255,.7);
      }
    }
}


.titledetail__event--container {

  .titledetail__event--date {

      color:#fff!important;

    // background: rgba(255,255,255,.05);

    .titledetail__event--date-day {
      font-weight: 600;
      text-transform: uppercase;
      font-size: 12px;
    }
    .titledetail__event--date-month {
      font-weight: 600;
      text-transform: uppercase;
      font-size: 1rem;
    }
    .titledetail__event--date-number {
      font-size: 2rem;
      font-weight: 400;
    }
  }
  .titledetail__event--title {
    font-size: 1.5em;
    line-height: 1.2em;
    font-weight: 600 !important;
    a {
      color:$white;
    }

  }
  .titledetail__event--time {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 1rem;
    color:#999;
    margin-top:5px;
  }
  .titledetail__event--rsvp {

  }
}

.home__event--container {

  .titledetail__event--date {

     color:#fff!important;
     transition:all .2s ease;

     &:hover {
         background-color: rgba(255,255,255,.05);
     }

    .titledetail__event--date-day {
      font-weight: 300;
      text-transform: uppercase;
      font-size: 12px;
    }
    .titledetail__event--date-month {
      font-weight: 300;
      text-transform: uppercase;
      font-size: 1rem;
    }
    .titledetail__event--date-number {
      font-size: 2rem;
      font-weight: 500;
    }
  }
  .titledetail__event--title {
    font-size: 1.2rem;
    line-height: 1.2em;
    font-weight: 600 !important;
    text-transform: uppercase;
    a {
      color:$white;
    }

  }
  .titledetail__event--time {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 1rem;
    color:#999;
    margin-top:5px;
  }
  .titledetail__event--rsvp {

  }
}



.events__header {
    font-size: 2.2rem;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 40px;
    //margin-top: 40px;
    letter-spacing: .75rem;
}
.events__rsvp--header {
    font-size: 1.5rem;
    // text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0px 0px 40px;
    letter-spacing: .75rem;
    padding-left:0px;
}

.events__grid {
  position: relative;
  z-index:1;

}

.events__grid--inner {
  //margin-left:-15px!important;
  //margin-right:-15px!important;
  position: relative;
  z-index:1;
}


.rsvpform__container {
  background: rgba(255, 255, 255, 0.07);

  select:focus {
    outline:0!important;
    border:0!important;
  }

  form .form-control {
    background:rgba(0, 0, 0, 0.2)!important;
    color:$white!important;
    opacity: 1!important;
    border-radius:0!important;
    border-bottom:1px solid $gravel!important;
    box-shadow:none!important;
    margin-bottom:-1px;
  }

  .form-group-smalltext {
    font-size: .8rem;
    opacity: .7;
    padding-left: 1.8rem;
    padding-top: .5rem;
  }

  .form-check-label {
    padding-left:10px;
  }

  .form-group {
    background:#161717!important;
    color:$white!important;
    opacity: 1!important;
    border-radius:0!important;
    border-bottom:1px solid $gravel!important;

    &.form-group-no-style {
      background:transparent!important;
      border-bottom:0px!important;
    }
  }

  .form-check {
    align-items:flex-start!important;

  }
  .form-check-input {
    margin-top:5px;
  }



}
.rsvpform__event--title {
  font-size:2.5rem;
}

.rsvpform__event--meta {
  font-size:1.25rem;
}


.validation__message {
  margin-bottom: 15px;
  font-size: .8rem;
  font-weight: bold;
  color: #e50914;
  // display: none;
}


.rhap_container {
    background-color: #171717;
}
.rhap_time {
  color:#f5f5f5;
}



.label-new {
  position: absolute;
    top: .5rem;
    right: 1.5rem;
}



  .titledetail__mediaitem {

    color:#fff!important;
    opacity: .7;
    @include transition-all(.3s);

    .titledetail__mediaitem--title  {
      line-height: 1.3em;
      font-size: 0.9rem;
      margin:.7rem 0;
      font-weight: 600;
    }
    .titledetail__mediaitem--description  {
        font-size: .8rem;
        line-height: 1.2em;
        color:#aaa;
      @include transition-all(.3s);
    }

    &:hover {
      opacity: 1;

      .titledetail__mediaitem--title  {

      }
      .titledetail__mediaitem--description  {
        color:#fff;
      }

    }

  }


.titledetail__mediaitem--thumbnail-container {
  position: relative;
  overflow: hidden;
}
.titledetail__mediaitem--placeholder {
    opacity: 0;
}
.titledetail__mediaitem--thumbnail {
  position: absolute;
  top: 50%;
  left:50%;
  transform: translate(-50%,-50%);
  min-width: 100%;
  min-height: 100%;

  &.titledetail__mediaitem--contained {
    max-width: 100%;
    max-height: 100%;
    min-width: auto;
    min-height: auto;
  }
}



.titlemedia__row--container {
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
  height: 220px;
  width: 100%;
  // white-space:nowrap


}

.titlemedia__row {
  position: absolute;
  top:0px;
  left:0px;
}



.winner-label {
  font-size: 9px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: .1rem;
  line-height: 1rem;
  max-width: 160px;
    margin: 0 auto;
}

.line-small {
  // opacity:.4;
  transform: translateY(-50%);
  border-color:$nflxred!important;
  width:2rem;
}
.line-small-grey {
  // opacity:.4;
  transform: translateY(-50%);
  border-color:#666!important;
  width:2rem;
}


.pdf__Page__canvas {
  width:100%!important;
}

.sag-ineligible  {
  opacity: .5;
}

.titledetail__audio--container {
  background:rgba(255,255,255,.05);
  padding: 12px;
  // margin-bottom: 10px!important;

  button {
    white-space: normal;
    text-align: left;
  }

  img {
    display: none;
  }

  &.titledetail__audio--container-playing {
    background:rgba(255,255,255,.15);
    -webkit-box-shadow: 0px 0px 60px 0px rgba(0,0,0,1);
    -moz-box-shadow: 0px 0px 60px 0px rgba(0,0,0,1);
    box-shadow: 0px 0px 60px 0px rgba(0,0,0,1);
  }

}

.titledetail__audio--icon  {
  margin-right:10px;
  color:$trinity;
  font-size:1.2rem;
  &.titledetail__audio--icon-highlighted {
    color:$nflxred;
  }
}






// .nomination__sidebar {
//   .nomineelist__nominee--person {
//     display:none!important;
//   }
// }

.nomination__columns {

  .sidebar__container--nomineelist {
    justify-content: center!important;
  }

  .titledetail__nominations--competition-title {
    margin:0!important;
    width:100%!important;
    padding:0!important;
    color:#fff!important;

    > span {
      text-align:center;
      // background: rgba(0,0,0,.2);
      margin-bottom: 1rem;
      font-size: .7rem!important;
      font-weight: lighter;
      padding: 0.7rem 0!important;
    }

    .competition-icon  {
      display:block!important;
    }
    .competition-name {
      color:#e50914!important;
      font-size:.8rem!important;
    }
  }

  .awards-rule {
    display:none;
  }

  .nomineelist__nominee  {

    .nomineelist__nominee--award {
      // font-size: 1rem;
      // // text-transform: none;
      // letter-spacing: 0!important;
      line-height: 1.25em;
      opacity: .5;
    }

    .nomineelist__nominee--person {
      line-height: 1.35em;
    }

  }

}



.page__container {

  .page__content {

    max-width:100%;
    margin:0px auto;

    h2, h3, h4 {
      text-align:center;
      display:block;
      font-weight:$font-bold;
      font-size:20px;
      margin-top:45px;
      &:first-child {
        margin-top:0px;
      }
    }

    p {

      font-size:16px;
      line-height:1.6em;
      margin-top:15px;
    }

    ul {
      list-style-type:disc;
      padding-left:2rem;
      li {
        line-height:1.6em;
      }
      margin-top:15px;
    }

  }

}


.captcha-container {
  a {
    display:none;
    color:#999!important;
  }
}

.event__excerpt--home-link {
  background: rgba(255,255,255,.05);
  border-radius: 5px;
  transition:all .2s ease;
  display: block;

  &:hover {
    background: rgba(255,255,255,.08);
  }
}



.titledetail__mediaalbum--description {
  font-size: 1rem;
  line-height: 1.3rem;
}



.genre-section-title-custom {
    font-size: 1.25rem;
    text-transform: uppercase;
    letter-spacing: .5rem;
    font-weight:lighter;
  line-height: 1.2em;
}



.footer__container {
  // background-color:$black;
  padding-bottom:40px;
  z-index: 11;
  position: relative;
  border-top:1px solid #333;
  pointer-events: none;

  .footer__content {
    // padding:40px 20px 0px;

    pointer-events: none;

    a {
      pointer-events: all;
    }

    .footer__logo {
      width:20px;
    }

    .footer__title {
      span {
        font-size:12px;
        text-transform: uppercase;
        display: block;
        // margin-left:20px;
      }
      .footer__title--main {
        font-weight:$font-bold;
        margin-bottom:3px;
      }
      .footer__title--tagline {
        color:$white;
        font-size:10px;
      }
    }

    .footer__links {
      a {
        font-size:14px;
        padding:0px 10px;
        font-weight:$font-bold;
        color:$white;
      }
    }

    .footer__copyright {
      flex-basis: 100%;
      margin: 20px 0px 0px;
      font-size: 10px;
    }

  }

  .footer__social {
    .footer__social-icon {
      max-width:40px;
      padding-left:10px;
      cursor:pointer;
      @include transition-all(.3s);
      opacity:.25;
      img {
        width:100%;
      }
      &:hover {
        opacity:1;
      }
    }
  }


}

.footer__container--small-outer {
    // height: 100%;
    // width:100%;
    // height: 100vh;
    // width:100vw;
    // position: fixed;
    // top:0;
    // left:0;
    // z-index: 11;
    // pointer-events: none;
    .footer__container--small {
        // z-index: 11;
        border-top:0px;
        // position: absolute;
        // bottom: 0;
        // left: 0;
        width: 100%;
        // pointer-events: all;
    }
}


.genre-featured-label:not(:first-child) {
  &:before {
    content:', ';
  }
}




.episodegrid__item-container {

  //color:#ddd;

  .episodegrid__item-title  {
    line-height: 1.3em;
    font-size: 0.9rem;
    margin:.7rem 0;
    font-weight: 600;
  }
  .episodegrid__item-description  {
    font-size: .8rem;
    line-height: 1.2em;
    color:#aaa;
    @include transition-all(.3s);
  }

}

.episodelist__item-container {

  //color:#ddd;

  &.active {
    background-color: rgba(255,255,255,.1);
  }

  .episodelist__item-title  {
    line-height: 1.3em;
    font-size: 0.8rem;
    margin:0 0 .3rem 0;
    font-weight: 600;
  }
  .episodelist__item-description  {
    font-size: .7rem;
    line-height: 1.2em;
    color:#aaa;
    @include transition-all(.3s);
  }

}

.indee-project-title {
  line-height: 1.3em;
  font-size: 1.5rem;
  margin:0 0 2rem 0;
  font-weight: 600;
}








.home-email-input {
  position: relative;

  .arrow {
    position: absolute;
    top:50%;
    transform:translateY(-50%);
    right:5px;
    cursor: pointer;
    width: 20px;
    opacity: .5;
  }
  label {
    color:#e50914;
    //color:rgba(255,255,255,.5);
    font-size: .6rem;
    text-transform: uppercase;
    letter-spacing: .3rem;
    line-height: 1em;
  }
  input {
    border:1px solid rgba(255,255,255,.2);
    background-color:rgba(255,255,255,.1);
    backdrop-filter: blur(5px);
    color:rgba(255,255,255,.8);
    font-size: .8rem;

    &:focus {
      outline:0;
      border:1px solid rgba(255,255,255,.2);
    }

    &::placeholder {
      color: rgba(255,255,255,.8);
      opacity: 1; /* Firefox */
    }

    &::-ms-input-placeholder { /* Edge 12 -18 */
      color: rgba(255,255,255,.8);
    }
  }
}


.emailsignup-modal-container {
  //position: relative;
  p {
    color: #aaa;
    margin: 0 auto;
    //max-width: 250px;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.4em;
  }
  input {
    border:1px solid rgba(255,255,255,.2);
    background-color:rgba(255,255,255,.1);
    backdrop-filter: blur(5px);
    color:rgba(255,255,255,.8);
    font-size: .8rem;
    font-weight:300;

    &:focus {
      outline:0;
      border:1px solid rgba(255,255,255,.2);
      background-color:rgba(255,255,255,.1);
      backdrop-filter: blur(5px);
      color:rgba(255,255,255,.8);
    }

    &::placeholder {
      color: rgba(255,255,255,.8);
      opacity: 1; /* Firefox */
    }

    &::-ms-input-placeholder { /* Edge 12 -18 */
      color: rgba(255,255,255,.8);
    }
  }
  .guild-input {
    .form-control {
      color:#f9f9f9;
      background: transparent;
      border: none;
      border-bottom:1px solid #f9f9f9;
      border-radius: 0px;
      font-size:12px;
      font-weight: 300;
      //opacity: .25;
    }

  }
}


.page__content-privacy {
  .c16 {
    font-weight: bold;
  }
    li {
      margin-left:2rem;
      line-height: 1.5em;
      font-size: inherit;
    }
  ul {
    li {
      list-style-type: circle;
      margin-left:0;
    }
  }
  ol {
    li {
      list-style-type: decimal;
    }
  }

}

.privacy-table {
  background-color: rgba(0,0,0,.5);

  .divTableBody {
    border:1px solid rgba(255,255,255,.2);
    .divTableRow {
      display: flex;
      width: 100%;
      .divTableCell {
        padding: 1rem;
        border-bottom: 1px solid rgba(255,255,255,.2);
        width:50%;
        word-break: break-word;
        &:nth-child(1) {
          font-weight: bold;
        }
        &:nth-child(2) {
          border-left: 1px solid rgba(255,255,255,.2);
        }
      }
    }
  }


}



@keyframes screenerLinkAnim {
  0% {
    width:0%;
  }
  100% {
    width:100%;
  }
}

.screenerLink {
  display: block;
  transition: all .5s ease;
  position: relative;

  &.screenerLinkLoading {

    &:after {
      content: '';
      transition: all .5s ease;
      animation: 7s none 0s 1 screenerLinkAnim;
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 0%;
      border-bottom: 1px solid #ccc;
    }
  }
}

.screenerButton {
  //display: block;
  //transition: all .5s ease;
  position: relative;
  &.screenerButtonLoading {

    &:after {
      content: '';
      transition: all .5s ease;
      animation: 7s none 0s 1 screenerLinkAnim;
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 0%;
      border-bottom: 1px solid #e50914;
    }
  }
}


.pdf-embed-wrapper {
  height:300px;
  top:0px;
}










































@import 'fysee';


@media (min-width: $break-sm) {

  .header__container {
    .header__sticky {
      // padding:0px 20px;
    }
    .header__title {
      .header__title--link {
        &:focus {
          color:$nflxred;
        }
      }
      span {
        font-size:12px;
      }
      .header__title--tagline {
        font-size:10px;
      }
    }
    .header__logo-wrapper {
      .header__logo {

      }
    }
  }

  .subnav__container {
    .dropdown__container {
      .dropdown__label {
        font-size: 14px;
      }
    }
  }





}

@media (min-width: $break-md) {




  button {
    font-size: 11px;
  }

  .hero__container {

    .hero__background {
      background-position: center top;
    }

  }

  .subnav__container {
    .dropdown__container {
      .dropdown__label {
        font-size: 16px;
      }
    }
  }


  .main__container {
    .main__background {

      // background-size: contain;
      // opacity: .2;
      filter:blur(0px);
      &.titledetail__background {
        opacity:1;
        height:70vh;
      }
    }
  }




  .search__container {
    // margin:50px 0 30px;
  }

  .genres__container {
    .genre__panel-container {
      padding:10px;
      .genre__panel {
        .genre__panel-content {
          // padding:10px;
          .genre__panel-content--inner {
            //font-size:10px;
          }
        }
      }
      &.break {
        .genre__panel {
          .genre__panel-content {
            .genre__panel-content--inner {
              //font-size:14px;
            }
          }
        }
      }
    }
  }

  .genre__container {

    position: relative;
    //top:75px;

    .genre__tier {
      .titleart__panel-container {
        padding:10px;
        max-width:30%!important;
      }
    }
  }

  .sidebar__container--titlepage {
    height:90vh;

      .sidebar__container--inner {
        min-height: 100%;

        .sidebar__container--explore-toggle {
          .sidebar__container--explore-toggle-link {
            padding: 1rem;
            // background-color: rgba(255,255,255,.2);

            &:hover,  &.active {
              // background-color: rgba(255,255,255,.35);
              // color:rgba(255,255,255,1);
              // opacity: 1;
              transform:translateY(-5px);
            }

          }
        }

      }
  }







  .main__background--controls {
    position: absolute;
    bottom:5rem;
    right:5rem;
    width: auto;
    margin-top: 0;
    margin-bottom: 0;

    .main__background--controls-tip {
      opacity: 0;
    }

  }


  .sidebar__container--titlepage {

    position: relative;
    z-index:3;
    height:auto;

    .sidebar__container--inner {

        .sidebar__container--nomineelist-wrapper {

          // position: absolute;

          .sidebar__container--nomineelist {
            // position: absolute;
          }
        }
      }
    }


    .sidebar__scrollcontainer--outer {

      .sidebar__scrollcontainer--inner {
        position: absolute;
        top:0px;
        bottom:0px;
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;

        .sidebar__scrollcontainer--content {
        }

      }
    }

    .text-small {
      font-size:.8em;
    }


    .footer__container--small-outer {
        height: 100%;
        width:100%;
        height: 100vh;
        width:100%;
        position: fixed;
        top:0;
        left:0;
        z-index: 11;
        pointer-events: none;
        .footer__container--small {
            z-index: 11;
            border-top:0px;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            //pointer-events: all;
        }
    }


    .titlepage__trailer--container {
      // transform: translateY(-2rem);
    }



    .pdf-embed-wrapper {
      height:100vh;
      padding-top:75px!important;
      padding-bottom:75px!important;
    }



}

@media (min-width: $break-lg) {

  .width_ctrl {
    max-width:$pageMaxWidth;
    //padding:0 2rem;
  }
  button {
    font-size: 14px;
  }

}

@media (min-width: $break-xl) {


  .hero__container {
    .hero__background {
      background-position: center -100px;
    }
  }
  .genres__container {
    .genre__panel-container {
      padding:15px;
      .genre__panel {
        .genre__panel-content {
          // padding:15px;
        }
      }
    }
  }
  .genre__container {
    .genre__tier {
      .titleart__panel-container {
        padding:15px;
      }
    }
  }
  button {
    // font-size: 16px;
  }

}
